<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          <b-row>
            <b-col
              cols="24"
              md="12"
            >
              <b-form-group
                :label="$t('form.href.label')"
                :label-for="'href'"
              >
                <b-form-input
                  :id="'href'"
                  v-model="item.href"
                  :state="errors && errors.href ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.href">
                  {{ errors.href[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.position.label')"
                label-for="position"
              >
                <b-form-input
                  id="position"
                  v-model="item.position"
                  :state="errors && errors.position ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.position">
                  {{ errors.position[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.blank.label')"
                label-for="blank"
              >
                <b-form-checkbox
                  id="blank"
                  v-model="item.blank"
                />
                <b-form-invalid-feedback v-if="errors && errors.blank">
                  {{ errors.blank[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('form.avatar.label')">
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->

                    <!-- reset -->
                    <b-button
                      v-if="item.avatar"

                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="item.avatar = null"
                    >
                      X
                    </b-button>
                    <!--/ reset -->
                  </b-media-aside>
                  <b-media-body class="mt-75 ml-75">
                    <InitFileManager
                      field="avatar"
                      type="navigation_item_avatar"
                      :multiple="false"
                      @fm-selected="selectedAvatar"
                    />

                    <b-row
                      v-if="errors && errors.avatar"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors.avatar[0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="errors && errors['avatar.type']"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors['avatar.type'][0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>

                  </b-media-body>
                </b-media>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button

            type="button"
            variant="outline-secondary"
            :to="{ name: 'admin:navigations-update', params: { id: $route.params.navigation_id } }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

import InitFileManager from '@/components/InitFileManager'

export default {
  components: {
    InitFileManager,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      items: [],
      productOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/admin/navigation_items', {
      params: {
        navigation_id: this.$route.params.navigation_id,
      },
    })
      .then(response => {
        this.items = response.data
      })

    this.item = await this.transformData()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        href: this.item.href,
        parent_id: this.item.parent_id,
        avatar: this.item.avatar,
        icon: this.item.icon,
        icon_mobile: this.item.icon_mobile,
        blank: this.item.blank,
        position: this.item.position,
        navigation_id: this.$route.params.navigation_id,
      }

      this.$http.post('/api/admin/navigation_items', data)
        .then(() => {
          router.replace({ name: 'admin:navigations-update', params: { id: this.$route.params.navigation_id } })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      const data = {
        avatar: null,
        icon: null,
        icon_mobile: null,
        parent_id: null,
        blank: false,
        position: 0,
        title: {},
        href: null,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
      })

      return data
    },
  },
}
</script>
